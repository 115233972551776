<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <div class="inter-content">
      <div class="orderinit-area container-fluid">
        <div class="order-area">
          <div class="hg-card text-center mt-4 mb-2">
            <img src="" width="40%" alt="" class="img-fluid">
          </div>

          <div class="text-center mt-20">
            <h4 class="mt-5">
              SSO 登入
            </h4>
            <div class="">
              重新導向中...
            </div>
          </div>
          <form ref="sso" method="post" :action="actionURL">
            <input
              v-for="(val, key) in sso"
              :key="key"
              :name="key"
              :value="val"
              type="hidden"
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { getHappyGoSiteCoode, getStoreCode, getSSOUrl } from '@/utils/tool'

export default {
  name: 'SSO',
  components: {
    HeaderInter
  },
  data() {
    let returnUrl = window.location.origin + '/user/sso-return'
    if (this.$route.params.redirect) {
      returnUrl += '/' + this.$route.params.redirect
    } else {
      returnUrl += '/'
    }
    return {
      actionURL: getSSOUrl(),
      sso: {
        returnURL: returnUrl,
        site: getHappyGoSiteCoode()
        // function: 'checkToken',
        // pageType: 'B',
        // extFunc: 'N',
        // btn: 'N',
      }
    }
  },
  created() {
    // 判斷各種商城ID 除了haapygo
    this.$store.commit('SET_LOADING', true)
    if (getStoreCode() === 'everrich') {
      window.location.href = getSSOUrl()
    } else {
      this.$nextTick(() => {
        this.$refs.sso.submit()
      })
    }
  },
  destroyed() {
    this.$store.commit('SET_LOADING', false)
  }
}
</script>
<style scoped lang="scss">
@import 'assets/scss/common.scss';
.box-group {
  margin: 0 auto 1.5rem;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);
  .box-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
    // border-bottom: 2px solid rgba($white-color, $alpha: 1);
    .box-th {
      // border-left: 2px solid rgba($main-color, $alpha: 1);
      padding: 5px 15px 5px 0px;
      white-space: nowrap;
    }
    .box-td {
      text-align: right;
      padding-left: 10px;
    }
  }
}

.orderinit-area .order-area {
  .sub-title {
    text-align: center;
    font-size: 1rem;
    color: rgba($main-color, $alpha: 1);
    background: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      color-stop(0.5, rgba($font-color, $alpha: 0.5)),
      color-stop(0.5, transparent),
      to(transparent)
    );
    background-repeat: repeat-x;
    background-position: center center;
    -webkit-background-size: 2px;
    display: block;
    margin: 0 auto 0.5rem;
    span {
      padding: 0 35px;
      background-color: $white-color;
    }
  }
  a {
    color: rgba($main-color, $alpha: 1);
  }
  a:hover {
    color: rgba($main-color, $alpha: 1);
    text-decoration: underline;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.orderinit-area > .order-area:last-child {
  padding-bottom: 5rem;
}

.main {
  padding-bottom: 100px;
  p {
    margin: 0 0 0.5rem;
    padding: 0;
  }
  dt {
    color: $main-color;
    margin-bottom: 0.35rem;
  }
  dd {
    font-size: 0.85rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
  }
  dd span {
    color: $sec-color;
  }
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
    .price {
      color: $notice-color;
    }
  }
}
</style>
